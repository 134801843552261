var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("section", {
              staticClass: "embed-container pbi-report",
              attrs: { id: "report-container" },
            }),
            _c("section", { staticClass: "error-container m-5" }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }