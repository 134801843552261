<script lang="ts" setup>
import { ref, watch, getCurrentInstance } from "vue";

import { getProjectReportsList } from "@/services/api/project.api";
import PowerbiViewer from "@/components/app/projects/PowerBIViewer";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { useUserStore } from "@/stores/user";

const { formatDateUtcD } = useDateTimeFormatter();
const userStore = useUserStore();

const reportId = ref();
const workspaceId = ref();
const componentKey = ref(0);
const selectedReportId = ref("");
const reports = ref([]);

watch(
  () => userStore.currentPartyReference,
  () => {
    console.log("PowerBISelector watch currentPartyReference: ", userStore.currentPartyReference);
    loadReports();
  },
  { immediate: true }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function selectReport(i) {
  if (reports.value.length >= i) {
    const report = reports.value[i];

    console.log("PowerBISelector selectReport ", i);
    if (report) {
      console.log("PowerBISelector selectReport workspaceId=" + report.workspaceId + " reportId=" + report.reportId);
      selectedReportId.value = i;
      workspaceId.value = report.workspaceId;
      reportId.value = report.reportId;
      componentKey.value++;
    } else {
      proxy.$toaster.error("Fout bij ophalen rapportage");
    }
  }
}

async function loadReports() {
  reports.value = [];
  workspaceId.value = undefined;
  reportId.value = undefined;
  // Ophalen lijst voor huidig project (voorkomt issues met switchen tussen projecten op de rapportpagina)
  var response = await getProjectReportsList();
  if (response && !response.error) {
    console.log("PowerBISelector loadReports ", response.data);
    reports.value = [...response.data];

    if (reports.value && reports.value.length > 0) {
      selectReport(0);
    } else {
      componentKey.value++;
    }
  }
  //console.log("PowerBISelector loadReports: ", reports.value);
}
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col lg="2" md="2" sm="12" class="pbi-report-sidebar pa-2">
        <h3 class="pa-3">Beschikbare rapportages</h3>
        <v-btn-toggle v-if="reports.length > 0" v-model="selectedReportId" @change="selectReport" class="pbi-report-sidebar">
          <v-list nav class="pbi-report-sidebar">
            <v-list-item text v-for="(itm, index) in reports" :key="index" :value="itm.id">
              <v-list-item-title>
                <v-btn color="white" class="form-control"
                  >{{ itm.name }} <br />
                  {{ formatDateUtcD(itm.reportDate) }}</v-btn
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-btn-toggle>
      </v-col>
      <v-col lg="10" md="10" sm="12" class="app-dashboard-overview">
        <powerbi-viewer :workspaceId="workspaceId" :reportId="reportId" :componentKey="componentKey" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.v-btn-toggle {
  flex-direction: column;
}
</style>
