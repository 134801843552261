<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import * as pbi from "powerbi-client";
import { getEmbeddedReport } from "@/services/api/powerbi.api";

const props = defineProps({
  workspaceId: {
    type: String,
    default: undefined,
  },
  reportId: {
    type: String,
    default: undefined,
  },
  componentKey: {
    type: Number,
    default: 0,
  },
});

// Voorkomt dat het powerbi script niet bij de DOM kan voordat de pagina is geladen
const ready = ref(false);

const hasReport = computed(() => {
  return ready.value && props.reportId != undefined && props.workspaceId != undefined;
});

watch(
  () => hasReport,
  () => {
    console.log("PowerBIViewer report changed, re-rendering report");
    loadReport();
  },
  { immediate: false }
);

watch(
  () => props.componentKey,
  () => {
    if (ready.value && props.componentKey != 0 && props.reportId != undefined && props.workspaceId != undefined) {
      console.log("powerbi componentKey changed, re-rendering report");
      loadReport();
    }
  }
);

onMounted(() => {
  ready.value = true;
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function loadReport() {
  const reportContainer = document.getElementById("report-container");

  if (props.workspaceId != undefined && props.reportId != undefined && props.workspaceId != "" && props.reportId != "") {
    const reportInfo = await getEmbeddedReport(props.workspaceId, props.reportId);

    if (reportInfo != undefined && reportInfo.data != undefined && reportInfo.data.token != undefined) {
      var permissions = pbi.models.Permissions.Read;

      var config = {
        type: "report",
        tokenType: pbi.models.TokenType.Embed,
        accessToken: reportInfo.data.token,
        embedUrl: reportInfo.data.reports[0].embedUrl,
        permissions: permissions,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true,
        },
      };

      const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      powerbi.reset(reportContainer); // Essentieel
      const report = powerbi.embed(reportContainer, config);

      report.off("loaded");
      report.off("rendered");

      report.on("error", function () {
        report.off("error");
        proxy.$toaster.error("Fout bij ophalen PowerBI rapportage");
      });
    } else if (reportInfo.error != undefined) {
      proxy.$toaster.error("Fout bij ophalen PowerBI rapportage");
      reportContainer.innerHTML = "<h4>De ingestelde rapportage kon niet getoond worden</h4><p>Neem contact op met uw INNAX contactpersoon om het probleem te verhelpen</p>";
    }
  } else {
    // In geval van geen aanwezige rapportages
    reportContainer.innerHTML = "<h4>Er is geen rapportage geconfigureerd voor dit project</h4><p>Vraag uw INNAX contactpersoon naar de mogelijkheden</p>";
  }
}
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <section id="report-container" class="embed-container pbi-report"></section>

        <!-- Used to display report embed error messages -->
        <section class="error-container m-5"></section>
      </v-col>
    </v-row>
  </v-container>
</template>
