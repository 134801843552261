import { apiClient } from "./httpClient";

const END_POINT = "powerbi";

const getEmbeddedReport = async (workspaceId, reportId) => {
	let errorResponse = null;
	const response = await apiClient.get(`${END_POINT}/embed`, { params : { workspaceId: workspaceId, reportId: reportId }}).catch(error => {
		errorResponse = error.toString();
	});
	if (errorResponse) {
		return { data: null, error: errorResponse };
	}
	return { data: response.data, error: errorResponse };
};

const getReportDetails = async (reportId) => {
	let errorResponse = null;
	const response = await apiClient.get(`${END_POINT}/${reportId}/detail`).catch(error => {
		errorResponse = error.toString();
	});
	if (errorResponse) {
		return { data: null, error: errorResponse };
	}
	return { data: response.data, error: errorResponse };
}

const addReport = async reportData => {
	const response = await apiClient.post(`${END_POINT}`, { ...reportData });
	return response;
};

const updateReport = async (reportId, reportData) => {
	const response = await apiClient.patch(`${END_POINT}/${reportId}`, { ...reportData });
	return response;
};

const deleteReport = async reportId => {
	const response = await apiClient.delete(`${END_POINT}/${reportId}`);
	return response;
};

export {
	getEmbeddedReport,
	getReportDetails,
	addReport,
	updateReport,
	deleteReport
}